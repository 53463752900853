/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';

import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";

import withWrapper from '../components/wrapper.js';

function _inner (props) {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;

  return (
    <div style={{ margin: "0px 10% 0px 10%" }}>
      <SEO title="All posts" />
      <Bio />
      <div style={{ margin: "20px 0 40px" }}>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginTop: rhythm(3 / 4),
                  marginBottom: 0,
                }}
              >
                <Link
                  style={{ boxShadow: `none` }}
                  to={`../blog${node.fields.slug}`}
                  isPartiallyCurrent={true}
                >
                  {title}
                </Link>
              </h3>
              <small style={{color:'rgba(0,0,0,.6)'}}>{node.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          );
        })}
      </div>
      <div style={{padding:'10px'}}>
        <center><a href='/createCompany' style={{textDecoration: 'none'}}><div style={{display: 'inline-block',border: '1px solid rgba(255,255,255,.75)', borderRadius: '4px', backgroundColor: 'black', color: 'white', fontSize:'20px',padding: '10px 35px',margin:'0px'}}>Value a Business</div></a></center>
        <div style={{color:'black',fontSize:'22px'}}><center>...it's free!</center></div>
      </div>

    </div>
  );
}

export default withWrapper(_inner);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
